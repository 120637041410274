<template v-clickoutside="close">
    <div class="dropdown-container" v-clickoutside="close">
        <a :disabled="disabled" class="icon-dropdown-trigger" @click="open = !open">
            <i :class="['fas', iconClass]" style="font-size: 1.25rem!important;"></i>
        </a>
        <div v-show="open" class="icon-dropdown-container">
            <div v-for="option in options" :key="option.name" style="width: 100%; z-index: 9999;">
                <button :disabled="option.disabled" class="button icon-dropdown-item" :style="option.style"
                    @click="() => { option.action(); open = false }">
                    {{option.name}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    iconClass: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    close () {
      this.open = false
    }
  }
}
</script>

<style scoped>
.dropdown-container {
  position: relative;
  display: inline-block;
  margin: 0 .5rem;
}

.icon-dropdown-trigger {
  cursor: pointer;
}

.icon-dropdown-container {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 9999;
  min-width: 150px;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.icon-dropdown-item {
  width: 100%;
  text-align: left;
  background-color: #fff;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
}

.icon-dropdown-item:hover {
  background-color: #f8f9fa;
}
</style>
