<template>
  <div class="is-fullwidth">
    <div>
      <div v-if="sortedRecords.length > 0 && (!isLoading || noResults)">
        <table class="table is-striped is-fullwidth" style="max-height: calc(100% - 70px); overflow-y: auto; margin: 0;">
          <thead>
            <tr>
              <th style="width: 100px">Job</th>
              <th style="max-width: " class="ellipsis">
                Name
              </th>
              <th style="width: 200px">Status</th>
              <th style="width: 250px">Start Time</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="record in sortedRecords" :key="record.runId"  style="max-height: 35px!important; height: 35px!important; overflow: hidden!important;">
              <td class="is-monospaced" style="max-height: 35px!important; height: 35px!important; overflow: hidden!important;">
                {{ getImportType(record.name) }}
              </td>
              <td class="is-monospaced name-container"  style="max-height: 35px!important; height: 35px!important; overflow: hidden!important;">
                <div class="name">
                  <div
                    class="tooltip-hover"
                    v-tooltip.top-center="{
                      content: getFileName(record.original_path)
                    }"
                  >
                    {{ getFileName(record.original_path) }}
                  </div>
                </div>
              </td>
              <td class="is-monospaced"  style="max-height: 35px!important; height: 35px!important; overflow: hidden!important;">
                <v-popover v-if="record.hadError">
                  {{ record.status }}
                  {{ record.hadError ? "(with error)" : "" }}
                  <template slot="popover" id="popover">
                    {{ record.error }}
                  </template>
                </v-popover>
                <span
                  v-else
                  :class="{
                    'has-text-danger': record.status === 'Failed',
                    'text-warning': record.status === 'Running Load',
                    'text-grey': record.status === 'Starting Load',
                    'text-info': record.status === 'Load Complete' || record.status === 'File Not Found',
                    'semi-bold': record.status === 'Running Load',
                    'font-italic': record.status === 'Running Load',
                    magnify: record.status === 'Running Load',
                  }"
                  >{{ record.status === 'File Not Found' ? 'Load Complete' : record.status }}</span
                >
              </td>
              <td  style="max-height: 35px!important; height: 35px!important; overflow: hidden!important;">
                <span class="is-monospaced">{{
                  moment(record.created_at)
                }}</span>
                <span
                  v-if="
                    getRunDiff(record.created_at) > 30 &&
                    record.status !== 'Load Complete' &&
                    record.status !== 'Pending Transform'
                  "
                >
                  <a
                    href="javascript:void()"
                    style="margin-left: 5px"
                    alt="Try to unjam the process"
                    @click="unjamProcess"
                  >
                    <i
                      v-if="!isUnjamming"
                      :class="['fas fa-redo']"
                      alt="refresh-icon"
                    ></i>
                    <i v-else class="fas fa-spinner fa-spin fa-pulse"></i>
                  </a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="paginator" style="position: sticky; bottom: 0; background-color: white; border-top: 1px solid #ededed;">
            <div class="paginator__left is-monospaced">
              <span><b>{{ commaNumberDisplay(total) }}</b> results (<b>{{ commaNumberDisplay(results) }}</b> per page)</span>
            </div>
            <div class="paginator-middle">
              <button
              class=""
              :disabled="page === 0"
              @click="page = 0"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192zm384-192l-192 192c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L301.3 256 470.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0z"/></svg>
            </button>
            <button
              class=""
              :disabled="page === 0"
              @click="page = page - 1"
            >
              <i class="fas fa-chevron-left"></i>
            </button>
            <button v-for="_page in paginationPages" :key="_page"  @click="page = _page" :class="{
                  'is-active': _page === page,
                }">
              <a>
                {{ _page + 1 }}
              </a>
            </button>
            <button
              class=""
              :disabled="page === Math.ceil(total / results) - 1"
              @click="page = page + 1"
            >
              <i class="fas fa-chevron-right"></i>
            </button>
            <button
              class=""
              :disabled="page === Math.ceil(total / results) - 1"
              @click="page = Math.ceil(total / results) - 1"
            >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-192-192c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 233.4 425.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l192-192zm-384 192l192-192c12.5-12.5 12.5-32.8 0-45.3l-192-192c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 41.4 425.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z"/></svg>
            </button>
          </div>
            <div class="paginator__right is-monospaced">
                <div>
                  <span>
                    <b>
                      {{ page + 1 }}
                    </b>
                  </span>
                  <span>of </span>
                  <span>
                    <b>{{ Math.ceil(total / results) }}</b>
                  </span>
                </div>
            </div>

        </div>
      </div>
      <div v-else-if="jobRecords.length == 0 && (!isLoading || noResults)" class="not-found">
        <div colspan="4" class="has-text-centered">No records founds</div>
      </div>
      <div v-else>
        <LoadingVue />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import LoadingVue from '../LoadingInline.vue'
import _ from 'lodash'

class JobsRun {
  mappedValues = {
    0: 'runId',
    1: 'path',
    2: 'name',
    3: 'original_path',
    4: 'outbox_path',
    5: 'status',
    6: 'locked',
    7: 'attempts',
    8: 'created_at',
    9: 'updated_at'
  };

  constructor (record = []) {
    let rmv = this.invertMappedValues()

    try {
      this.record = record
      this.runId = record[rmv.runId]
      this.path = record[rmv.path]
      this.name = record[rmv.name]
      this.original_path = record[rmv.original_path]
      this.outbox_path = record[rmv.outbox_path]
      this.status = record[rmv.status]
      this.locked = record[rmv.locked]
      this.attempts = record[rmv.attempts]
      this.created_at = record[rmv.created_at]
      this.updated_at = record[rmv.updated_at]

      this.originalFileName = null
      this.fileName = null
      this.runDate = null
      this.hadError = false
      this.error = null
    } catch (e) {
      // console.log(e)
    }
  }

  invertMappedValues () {
    let obj = {}
    for (let key of Object.keys(this.mappedValues)) {
      obj[this.mappedValues[key]] = key
    }
    return obj
  }
}

const urldecode = (str) => {
  return str.replaceAll('%20', ' ')
}

export default {
  name: 'JobsRuns',
  components: {
    LoadingVue
  },
  emits: ['hasRunningJob', 'noRecordsFound'],
  props: {
    refresh: {
      type: Boolean,
      default: false
    },
    search: {
      type: String,
      default: ''
    },
    filterVal: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      jobRecords: [],
      isUnjamming: false,
      isLoading: false,
      noResults: false,
      page: 0,
      results: 10,
      total: 0,
      isSearching: false
    }
  },
  watch: {
    refresh (val) {
      // console.log('refresh', val, this.search)
      if (val) {
        this.jobRecords = []
        this.isSearching = true
        this.getResultsCount()
        this.getRecords()
      }
    },
    search: function (val) {
      this.noResults = false
      this.isSearching = true
      this.jobRecords = []
      this.getRecords()
    },
    page: function (val) {
      this.jobRecords = []
      this.isLoading = true
      this.isSearching = true

      this.$nextTick(() => {
        this.getRecords()
      })
    }
  },
  computed: {
    paginationPages () {
      let pages = []
      let max = 5
      let start = this.page - Math.floor(max / 2)
      let end = this.page + Math.floor(max / 2)

      if (start < 0) {
        end = end - start
        start = 0
      }

      if (end > Math.ceil(this.total / this.results) - 1) {
        start = start - (end - (Math.ceil(this.total / this.results) - 1))
        end = Math.ceil(this.total / this.results) - 1
      }

      if (start < 0) {
        start = 0
      }

      for (let i = start; i <= end; i++) {
        pages.push(i)
      }

      return pages
    },
    sortedRecords () {
      // this.isLoading = true
      // let retRecords = []

      if (this.search !== '') {
        return _.orderBy(this.jobRecords, ['created_at'], ['desc']).filter(
          (record) => {
            return record.name
              .toLowerCase()
              .includes(this.search.toLowerCase())
          }
        )
      } else {
        return _.orderBy(this.jobRecords, ['created_at'], ['desc'])
      }

      // this.isLoading = false
      // return retRecords
    }
  },
  mounted () {
    window.requestAnimationFrame(() => {
      this.getResultsCount(() => this.getRecords())
    })
  },
  methods: {
    moment (date) {
      return this.$formatDateInLocalTimezone(date, 'MM/DD/YYYY hh:mm A') // moment(date).format('MM/DD/YYYY hh:mm A')
    },
    getRunDiff (date) {
      return (
        moment(
          this.$formatDateInLocalTimezone(date, 'MM/DD/YYYY hh:mm A')
        ).diff(moment(), 'minutes') * -1
      )
    },
    getFileName (record) {
      return urldecode(record).split('/').pop()
    },
    unjamProcess () {
      this.isUnjamming = true
      this.api().etl.unjam((result) => {
        this.getRecords()
        this.isUnjamming = false
      })
    },
    getImportType (record) {
      const type = record

      if (type.includes('/DISCLOSURES/')) {
        return 'DISCLOSURES'
      }

      if (type.includes('CINFO_ADJUSTMENTS') || type.includes('OUT_CINFO_ADJUSTMENTS') || type.includes('ADJUSTMENTS')) {
        return 'CINFO_ADJUSTMENTS'
      }

      if (type.includes('CINFO') || type.includes('OUT_CINFO')) {
        return 'CINFO'
      }

      if (type.includes('CARRIER_MM') || type.includes('MM')) {
        return 'MM'
      }

      if (type.includes('CARRIER_MNL') || type.includes('MNL')) {
        return 'MNL'
      }

      if (type.includes('CARRIER_NML') || type.includes('NML')) {
        return 'NML'
      }

      if (type.includes('CARRIER_WCL') || type.includes('WCL')) {
        return 'WCL'
      }

      if (type.includes('TAX_RATES') || type.includes('OUT_DATA_TAX_RATES')) {
        return 'TAX RATES'
      }

      if ((type.includes('.relationships') && type.includes('DISCLOSURES')) || type.includes('OUT_DATA_DISCLOSURE_RELATIONSHIPS') || type.includes('-relationships')) {
        return 'DISCLOSURES_RELATIONSHIPS'
      }

      if (type.includes('OUT_DATA_DISCLOSURES') || type.includes('DISCLOSURES')) {
        return 'DISCLOSURES'
      }

      return type !== '' ? type === 'https:' ? 'DISCLOSURES' : type : 'Unknown'
    },

    getResultsCount (callback) {
      let container = document.querySelector('.jobs-container--content')
      // let width = container.offsetWidth
      let height = container.offsetHeight - 70 - 88 - 30

      this.results = Math.floor((height) / 35) - 1

      if (callback) {
        window.requestAnimationFrame(() => {
          callback()
        })
      }
    },

    async performGetRecordsRequest () {
      this.isLoading = true
      this.api().etl.Jobs(this.search, this.filterVal, this.page, this.results, (result) => {
        const response = JSON.parse(result.data)
        if (response && Array.isArray(response.results) && response.results.length > 0) {
          this.total = parseInt(response.total) // response.total
          if (this.total === 0) {
            this.noResults = true
            this.$emit('noRecordsFound', true)
          } else {
            this.noResults = false
            this.$emit('noRecordsFound', false)
          }

          const records = response.results.map((record) => {
            return new JobsRun({ ...record })
          })

          this.jobRecords = [...records]
          this.isLoading = false
          this.isSearching = false

          this.$forceUpdate()
        } else {
          this.isLoading = false
          this.isSearching = false
          this.noResults = true
          this.$emit('noRecordsFound', true)
        }
      })
    },
    async getRecords () {
      this.performGetRecordsRequest()
    }
  }
}
</script>

<style>
.v-popover .trigger {
  cursor: pointer !important;
}

.popover-inner {
  word-break: break-all;
  width: calc(100% * 2.32);
}

.countdown {
  text-align: center;
  margin-top: -10px;
}

.magnify {
  animation: magnify 1s infinite !important;
  display: flex;
}

@keyframes magnify {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.text-warning {
  color: #eaae5e !important;
}

.text-grey {
  color: #7fb942 !important;
}

.text-info {
  color: #3d567d !important;
}

.fade {
  animation: magnify 1s infinite !important;
  /* opacity: .5!important; */
}

[isLoading="true"] {
  opacity: 0.5 !important;
  animation: isLoading 1s infinite !important;
}

@keyframes isLoading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.not-found {
  text-align: center;
  margin-top: 20px;
}

.paginator {
  padding: .5rem;
  /* margin-top: 20px; */
  text-align: center;

  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */

  width: 100%;
  background-color: whitesmoke;

  height: calc(50px + 1rem + 4px);

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.paginator__left {
  width: 150px;
}

.paginator__right {
  width: 150px;
}

.paginator__left, .paginator__right {
  display: flex;
  flex-direction: column;
  height: 50px;

  display: flex;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
}

.paginator__right {
  display: flex;
  align-items: flex-end;
  align-content: center;
  justify-content: center;
}

.paginator button {
  margin: 0 5px;
  background-color: #1f314d;
  color: white!important;
  border: none;
  padding: .25rem;
  border-radius: .25rem;
  cursor: pointer;

  height: 35px;
  width: 35px;
}

.paginator button svg {
  height: 17px;
  width: 17px;
  fill: white;
}

.paginator button a {
  color: white;
  text-decoration: none;
}

.paginator button:hover {
  background-color: #3d567d;
}

.paginator-middle {
  display: flex;
  align-items: center;
  justify-content: center;

  width: calc(100% - 300px);
}

b {
  color: #1f314d;
  font-weight: 600;
}

.paginator .is-active {
  background-color: #3d567d!important;
  color: white!important;
  transform: scale(1.3)!important;
}

tr {
  line-height: 15px!important;
  height: 35px!important;
  max-height: 35px!important;
  overflow: hidden;
  padding-block: 0!important;
}

tr td {
  line-height: 35px!important;
  height: 35px!important;
  max-height: 35px!important;
  padding-block: 0!important;
  overflow: hidden;
  vertical-align: middle;
  word-break: keep-all!important;
}
</style>

<style scoped>
.table.is-fullwidth {
  width: 100%;
  max-height: calc(100% - 70px - 68px);
  overflow: hidden;
}

.tooltip-hover {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
</style>
